
import { defineComponent } from "vue";
import Sidebar from "./Sidebar.vue";
import Header from "@/layout/Header.vue";
import store from "@/store";
import authStore from "@/store/auth-store";
import Toast from "@/components/Toast.vue";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { IEmployeeNewEdit } from "@/types/Employee";

export default defineComponent({
  name: "Layout",
  components: { Toast, Header, Sidebar },
  created(): void {
    this.sidebarToggle();
    window.addEventListener("resize", this.sidebarToggle);
    if (!this.employee || !this.organisation) {
      this.logOut();
    }

    this.interval = setInterval(() => {
      if (this.isAuth) {
        this.workForceService
          .get<IEmployeeNewEdit>(`/employee/${this.employee.id}`, false)
          .then((response: any) => {
            if (!response.isError) {
              if (this.employee.role != response.data.role) {
                this.logOut();
              }
            }
          });
      }
    }, this.intervalTime);
  },
  unmounted(): void {
    clearInterval(this.interval);
    window.removeEventListener("resize", this.sidebarToggle);
  },
  data() {
    return {
      show: true,
      toggleButton: false,
      authStore: authStore,
      workForceService: new WorkForceService(),
      interval: null as any,
      intervalTime: 60000,
    };
  },
  methods: {
    sidebarToggle(): void {
      if (window.outerWidth < 993) {
        this.show = false;
      } else {
        this.toggleButton = false;
        this.show = true;
      }
    },
    logOut: function () {
      this.authStore.removeToken();
      this.$router.push("/account/login");
      store.commit("removeOrgDetails");
      store.commit("removeAuthUser");
      store.commit("removeVersion");
      store.commit("removeToken");
    },
  },
  computed: {
    organisation() {
      return store.getters.orgDetails;
    },
    employee() {
      return store.getters.authUser;
    },
    isAuth() {
      return store.getters.isAuth;
    },
    releaseVersion() {
      return store.getters.version;
    },
  },
});
