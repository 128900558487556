
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import authStore from "@/store/auth-store";
import ProfileImage from "@/views/employees/ProfileImage.vue";

@Options({
  name: "Header",
  components: { ProfileImage },
  data: function () {
    return {
      authStore: authStore,
      accountList: [
        { text: "Profile", icon: "profile" },
        { text: "Logout", icon: "logout" },
      ],
    };
  },
  methods: {
    profile: function () {
      const tokenData = this.authStore.getTokenData();
      const employeeId = tokenData.EmployeeId;
      this.$router.push(`/admin/employee/edit/${employeeId}`);
    },
    logOut: async function () {
      this.authStore.removeToken();
      await store.commit("removeOrgDetails");
      await store.commit("removeAuthUser");
      await store.commit("removeVersion");
      await store.commit("removeToken");
      await store.commit("reset");
      this.$router.push({ path: "/account/login" });
    },
  },

  computed: {
    organisation() {
      return store.getters.orgDetails;
    },
    employee() {
      return store.getters.authUser;
    },
  },
})
export default class AdminLayout extends Vue {}
