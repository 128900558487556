
import { Options, Vue } from "vue-class-component";
import AuthStore from "@/store/auth-store";
import store from "@/store";

@Options({
  name: "sidebar",
  computed: {
    releaseVersion() {
      return store.getters.version;
    },
    authUser() {
      return store.getters.authUser;
    },
    id() {
      return btoa(store.getters.authUser.id);
    },
    name() {
      return btoa(
        store.getters.authUser.firstName + " " + store.getters.authUser.lastName
      );
    },
  },
})
export default class AdminLayout extends Vue {
  protected authStore = AuthStore;
  tokenData = this.authStore.getTokenData();
}
